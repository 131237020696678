import React, { Component } from 'react'

import PDF3 from "../documents/Apartly.pdf"
import IMG_figma from '../images/figma.svg'
import IMG_apartly from '../images/apartly_banner.png'

export default class Project extends Component {

    componentDidMount(){
        document.title = "Vivien Geschwind | About"
        window.scrollTo(0,0)
      }

    render() {
        return (
            <section id="apartly">


<div class="project-container">
    <div>
<div class="project-heading">UX/UI & Design System <br/>
    for Fintech startup Apartly</div>
    <br/>

         <div class="button-block">
          <a class="proto-button" href="https://www.figma.com/proto/LwCfAyLZaA0P1umhRj4cvL/Apartly-Design-Systems?page-id=7%3A1650&node-id=7-1651&node-type=canvas&viewport=109%2C255%2C0.13&t=VUOD3Pw4qiFG5Ope-1&scaling=contain&content-scaling=fixed" target="_blank"><div class="button-content"><img src={IMG_figma} width='20px'></img><div>Design System</div></div></a>
          
          <a class="proto-button" href={PDF3} target="_blank">UX/UI Design</a>
          </div>
          </div>
          <div class="apartly-banner"><img src={IMG_apartly}></img></div>
          </div>
          
          {/* <div>Scroll to Beta Version</div>
          <div>Scroll to Website Redesign</div> */}

{/* <br/>
<br/>
<h1>Apartly Beta Version</h1>
<div> */}




{/* 
<h2>Investing Flow</h2>

<h2>Leaderboard</h2>
<div>To enhance engagement with the Beta platform, a “return-on-investment” competition was introduced. The competition was based on a housing market simulation. The simulation assigned different growth rates to various properties, meaning that participants with different property choices will see varying returns. A dynamic leaderboard displayed participants with the highest returns at any given moment, and the user with the highest return by the end of the period was declared the winner.</div> */}

          {/* <div>This project was developed as part of my bachelor's thesis in cooperation with the Robert Bosch start-up spexor. </div> */}
          </section>
        )
    }
}

